<template>
<div class="interact-detail">
  <MyBreadcrumb></MyBreadcrumb>
  <div class="interact-detail-top">
    <p class="interact-detail-top-tit">{{ interactInfo.name }}</p>
    <div class="interact-detail-top-r" v-if="!taskOver">
      <p v-if="remainTime > 0">{{surplusTime.hours ? (surplusTime.hours < 10 ? '0' + surplusTime.hours : surplusTime.hours) : '00'}}:{{surplusTime.minutes ? (surplusTime.minutes < 10 ? '0' + surplusTime.minutes : surplusTime.minutes)  : '00'}}:{{surplusTime.seconds ? (surplusTime.seconds < 10 ? '0' + surplusTime.seconds : surplusTime.seconds) : '00'}}</p>
      <div v-if="remainTime > 0" @click="interactTaskOver">结束答题</div>
    </div>
  </div>
  <div class="interact-detail-cont">
    <div class="interact-detail-tab">
      <p :class="curTab.id == item.id ? 'active' :''" v-for="item in tabList" :key="item.id" @click="changeTab(item)">{{ item.name }}</p>
    </div>
    <component :is="curTab.componentName" :dataStatStatus="dataStatStatus" :submitUserCount="submitUserCount" :userListLoading="userListLoading" :trainId="trainId" :taskOver="taskOver" :curStatus="curStatus" :dataList="dataList" :userList="userList" :statusList="statusList" @changeUserStatus="changeUserStatus" @getSimpleDataDetail="getSimpleDataDetail" @getOptionDetail="getOptionDetail"></component>
  </div>
  <!--  单选、多选、判断   整道题的答题详情-->
  <el-drawer
      title="答题详情"
      :visible.sync="dataModal"
      footer-hide
      size="650px"
      @close="closeDrawer"
  >
    <div class="drawer-title" slot="title">
      <div class="drawer-title-text">答题详情<span class="answer-detail-tit-fgx">|</span>共<span class="blue">{{userlistNum}}</span>人参与此题</div>
    </div>
    <div>

      <div class="answer-detail-data">
        <p class="answer-detail-data-tit">
          <Richtxt :dataObj="{val:dataDetail.data && dataDetail.data.name}" :isInline="true" :isDisabled="true"></Richtxt>
        </p>
      </div>
      <div class="answer-detail-user-tit">{{correctUser.length}}人答对：</div>
      <div class="answer-detail-user">
        <div class="answer-detail-user-item" v-for="item in correctUser" :key="item.id">
          <img :src="item.user.avatar" width="68" height="68" />
          <p>{{ item.user.realname || item.user.nickname }}</p>
          <p>ID{{ item.user.id }}</p>
        </div>
        <div class="no-data" v-if="!correctUser.length">
          <img src="../../assets/images/new_icon/nodata.png" width="158" height="82" />
          <p>暂无数据</p>
        </div>
      </div>
      <div class="answer-detail-user-fgx"></div>
      <div class="answer-detail-user-tit">{{errorUser.length}}人答错：</div>
      <div class="answer-detail-user">
        <div class="answer-detail-user-item" v-for="item in errorUser" :key="item.id">
          <img :src="item.user.avatar" width="68" height="68" />
          <p>{{ item.user.realname || item.user.nickname }}</p>
          <p>ID{{ item.user.id }}</p>
        </div>
        <div class="no-data" v-if="!errorUser.length">
          <img src="../../assets/images/new_icon/nodata.png" width="158" height="82" />
          <p>暂无数据</p>
        </div>
      </div>
    </div>


  </el-drawer>
  <!--  填空、简答、实验   整道题的答题详情-->
  <el-drawer
      title="答题详情"
      :visible.sync="markDataModal"
      footer-hide
      size="650px"
      @close="closeDrawer"
  >
    <div class="drawer-title" slot="title">
      <div class="drawer-title-text">答题详情<span class="answer-detail-tit-fgx">|</span>共<span class="blue">{{userlistNum}}</span>人回答此题目</div>
    </div>
    <div >

      <div class="answer-detail-data">
        <p class="answer-detail-data-tit">
          <Richtxt :dataObj="{val:dataDetail.data && dataDetail.data.name}" :isInline="true" :isDisabled="true"></Richtxt>
        </p>
        <template v-if="dataDetail.data && dataDetail.data.type == 4">
          <div class="answer-detail-data-option" v-for="item in dataDetail.data.options" :key="item.id">
            <p>
              <Richtxt :dataObj="{val:item.name}" :isInline="true" :isDisabled="true"></Richtxt>
            </p>
          </div>
          <div class="answer-detail-data-tag">正确答案</div>
        </template>

      </div>
      <div class="answer-detail-stat" >
        {{markUser.length}}人待阅<span>|</span>{{correctUser.length}}人答对<span>|</span>{{errorUser.length}}人答错
      </div>
      <div class="answer-detail-search">
        <div class="answer-detail-search-item">
          <div>状态:</div>
          <el-select v-model="answerStatus"
                     placeholder="请选择"
                     style="width: 120px"
                     @change="searchDataUser"
                     clearable
          >
            <el-option
                v-for="item in answerList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="answer-detail-search-item">
          <div>学生搜索:</div>
          <el-input
              placeholder="请输入内容"
              prefix-icon="el-icon-search"
              v-model="keyword"
              style="width: 200px"
              @change="searchDataUser"
              clearable
          >
          </el-input>
        </div>
      </div>
      <div class="answer-detail-user-list">
        <div class="answer-detail-user-list-item" v-for="item in dataDetail.list" :key="item.id">
          <img :src="item.user.avatar" width="44" height="44" />
          <div class="answer-detail-user-list-item-info">
            <div class="answer-detail-user-list-item-info-top">
              <p class="name">{{  item.user.realname || item.user.nickname  }}</p>
              <p>（ID{{ item.user.id }})</p>
              <p class="tag" :class="tagStatus[item.is_right]">{{tagName[item.is_right]}}</p>
            </div>
            <div class="answer-detail-user-list-item-info-file" v-if="dataDetail.data && dataDetail.data.type == 9">
              <img src="../../assets/images/new_icon/wenjian.png" width="30" height="36" />
              <p class="answer-detail-user-list-item-info-file-name">文件名：{{item.answer}}</p>
              <div class="answer-detail-user-list-item-info-btns">
                <div @click = download(item.answer)>下载</div>
                <div class="success" v-if="item.is_right == '2'" @click="isRight(item)">回答正确</div>
                <div class="error" v-if="item.is_right == '2'" @click="isWrong(item)">回答错误</div>
              </div>
            </div>
            <div v-else>
              <div class="answer-detail-user-list-item-info-answer">
                <Richtxt :dataObj="{val:item.answer}" :isInline="true" :isDisabled="true"></Richtxt>
              </div>
              <div class="answer-detail-user-list-item-info-btns mt20" v-if="item.is_right == '2'">
                <div class="success" @click="isRight(item)">回答正确</div>
                <div class="error" @click="isWrong(item)">回答错误</div>
              </div>
            </div>
          </div>
        </div>
        <div class="no-data" v-if="dataDetail.list && !dataDetail.list.length">
          <img src="../../assets/images/new_icon/nodata.png" width="158" height="82" />
          <p>暂无数据</p>
        </div>
      </div>
    </div>

  </el-drawer>
  <!--  单选、多选、判断  每个答案的详情-->
  <el-drawer
      title="答题详情"
      :visible.sync="simpleDataModal"
      footer-hide
      size="650px"
      @close="closeDrawer"
  >
    <div class="drawer-title" slot="title">
      <div class="drawer-title-text">答题详情<span class="answer-detail-tit-fgx">|</span>共<span class="blue">{{userlistNum}}</span>人选择此答案</div>
    </div>
    <div class="answer-detail-data">
      <p class="answer-detail-data-tit">
        <Richtxt :dataObj="{val:dataDetail.data && dataDetail.data.name}" :isInline="true" :isDisabled="true"></Richtxt>
      </p>
      <div class="answer-detail-data-option">
        <p>{{String.fromCharCode(optionIndex+65)}}.</p>
        <p>
          <Richtxt :dataObj="{val:dataDetail.data && dataDetail.data.options.length &&  dataDetail.data.options[0].name}" :isInline="true" :isDisabled="true"></Richtxt>
        </p>
      </div>
      <div class="answer-detail-data-tag" v-if="dataDetail.data && dataDetail.data.options.length &&  dataDetail.data.options[0].is_correct == '1'">正确答案</div>
    </div>
    <div class="answer-detail-user" >
      <div class="answer-detail-user-item" v-for="item in dataDetail.list" :key="item.id">
        <img :src="item.user.avatar" width="68" height="68" />
        <p>{{ item.user.realname || item.user.nickname }}</p>
        <p>ID{{ item.user.id }}</p>
      </div>
      <div class="no-data" v-if="dataDetail.list && !dataDetail.list.length">
        <img src="../../assets/images/new_icon/nodata.png" width="158" height="82" />
        <p>暂无数据</p>
      </div>
    </div>
  </el-drawer>
</div>
</template>

<script>
import AnswerStat from './components/interact/answerStat'
import Rank from './components/interact/rank'
import DataStat from './components/interact/dataStat'
import util from '@/utils/tools.js';
import Richtxt from '@/components/production/richtxt.vue';
import FileSaver from "file-saver";
export default {
  name: "interactDetail",
  data(){
    return{
      tabList:[
        {
          name:'实时答题统计',
          id:'1',
          tab:'answerStat',
          componentName:'AnswerStat'
        },
        {
          name:'答题排行',
          id:'2',
          tab:'rank',
          componentName:'Rank'
        },
        {
          name:'题目统计',
          id:'3',
          tab:'dataStat',
          componentName:'DataStat'
        },
      ],
      curTab:{
        name:'实时答题统计',
        id:'1',
        tab:'answerStat',
        componentName:'AnswerStat'
      },
      dataList:[],
      userList:[],
      interactTaskId:'',
      curStatus:'-1',
      remainTime:'',
      interactInfo:{},
      surplusTime:{},
      timer:null,
      timer1:null,
      intervalTimer:null,
      taskOver:false,
      trainId:'',
      dataModal:false,
      markDataModal:false,
      simpleDataModal:false,
      tagStatus:{
        '0':'error',
        '1':'success',
        '2':'mark'
      },
      tagName:{
        '0':'错误',
        '1':'正确',
        '2':'待阅'
      },
      dataDetail:{},
      curData:{},

      optionIndex:'',
      curUserData:{},
      correctUser:[],
      errorUser:[],
      markUser:[],
      keyword:'',
      answerList:[],
      answerStatus:'',
      loading:true,
      userListLoading:true,
      statusList:[],
      submitUserCount:'0',
      dataStatStatus:false,
      userlistNum:0,
    }
  },
  components:{
    AnswerStat,
    Rank,
    DataStat,
    Richtxt
  },
  created(){
    this.interactTaskId = this.$route.query.id || '';
    this.getDataDetail();
    this.getUserList();
    this.getCourseForm();
  },
  methods:{
    changeTab(data){
      this.curTab = data;
    },
    getDataDetail(type){
      let params = {
        interact_task_id:this.interactTaskId
      };
      this.loading = true;
      this.api.course.courseInteractTaskDataStat(params).then((res)=>{
        this.loading = false;
        this.dataList = res.data_list;
        this.interactInfo = res.interact;
        this.trainId = res.train.id;
        if(res.info.status == 1){
          this.taskOver = true;
          clearTimeout(this.timer);
          clearTimeout(this.timer1);
          clearInterval(this.intervalTimer);
        }else{
          if(!type){
            this.remainTime = res.info.remain_time;
            this.surplusTime = util.timeFormate(this.remainTime);
          }

          if(this.remainTime >= 10){
            clearTimeout(this.timer);
            this.timer = setTimeout(()=>{
              this.getDataDetail('notime')
            },10000);
            clearTimeout(this.timer1);
            this.timer1 = setTimeout(()=>{
              this.getUserList()
            },10000);
          }else{
            clearTimeout(this.timer);
            if(this.remainTime <= 0){
              this.taskOver = true;
            }
          }
          if(this.remainTime > 0){
            this.countDownTime()
          }
        }


      }).catch((err)=>{
        this.loading = false;
      })
    },
    countDownTime(){
      clearInterval(this.intervalTimer);
      this.intervalTimer = setInterval(()=>{
        this.remainTime--;
        this.surplusTime = util.timeFormate(this.remainTime);
        if(this.remainTime == 0){
          clearInterval(this.intervalTimer);
          this.getDataDetail();
          this.getUserList();
        }
      },1000)

    },
    changeUserStatus(data){
      this.curStatus = data;
      this.getUserList();
    },
    getUserList(){  //获取提交名单
      let params = {
        interact_task_id:this.interactTaskId,
        is_submit:this.curStatus
      }
      this.userListLoading = true;
      this.api.course.courseInteractTaskUserList(params).then((res)=>{
        this.userListLoading = false;
        this.userList = res.list;
        this.submitUserCount = res.submit_user_count;
        // if(this.remainTime >= 10){
        //   clearTimeout(this.timer1);
        //   this.timer1 = setTimeout(()=>{
        //     this.getUserList()
        //   },10000);
        // }else{
        //   clearTimeout(this.timer1);
        // }
      }).catch((err)=>{
        this.userListLoading = false;
      })
    },
    interactTaskOver(){ //结束答题
      let params = {
        interact_task_id:this.interactTaskId,
      };
      this.api.course.courseInteractTaskOver(params).then((res)=>{
        clearTimeout(this.timer);
        clearTimeout(this.timer1);
        clearInterval(this.intervalTimer);
        this.getDataDetail('notime');
        this.getUserList();
        this.taskOver = true;
      })
    },
    getOptionDetail(data){  //选项详情
      this.optionIndex = data.index;
      this.getSimpleDataDetail(data.data,data.option);
    },
    getSimpleDataDetail(data,option){  //试题详情
      this.dataStatStatus = true;
      this.curData = data;
      this.curOption = option;
      if(option){
        this.simpleDataModal = true;
      }else{
        if(data.type == 1 || data.type == 2 || data.type == 3){
          this.dataModal = true;
        }else{
          this.markDataModal = true;
          this.answerStatus = '';
        }
      }


      let params = {
        interact_task_id:this.interactTaskId,
        data_id:data.id,
        is_right:this.answerStatus,
        keyword:this.keyword
      }
      if(option){
        params.option_id = option.id;
      }
      //获取试题用户列表
      this.api.course.courseInteractDataUserList(params).then((res)=>{
        this.dataDetail = res;
        this.userlistNum = this.dataDetail.list.length;
        this.correctUser = this.dataDetail.list.filter((sItem)=>{
          return sItem.is_right == '1'
        });

        this.errorUser = this.dataDetail.list.filter((sItem)=>{
          return sItem.is_right == '0'
        });

        this.markUser = this.dataDetail.list.filter((sItem)=>{
          return sItem.is_right == '2'
        });

      })
    },
    searchDataUser(){
      let params = {
        interact_task_id:this.interactTaskId,
        data_id:this.curData.id,
        is_right:this.answerStatus,
        keyword:this.keyword
      }
      if(this.curOption){
        params.option_id = this.curOption.id;
      }
      //获取试题用户列表
      this.api.course.courseInteractDataUserList(params).then((res)=>{
        this.dataDetail = res;
      })
    },
    getCourseForm(){
      this.api.course.courseForm().then((res)=>{
        this.statusList = [{
          name:'全部',
          id:'-1'
        }];
        for(let name in res.submits){
          this.statusList.push({
            id:name,
            name:res.submits[name]
          })
        }

        this.answerList = [];
        this.answerList = util.jsonToArr(res.data_answer_check_statuses);

      })
    },

    download(data){
      let name = data.split('/');
      name = name[name.length -1].split('.');
      name = name[0] + '.' + name[1];
      FileSaver.saveAs(data,name);
    },
    isRight(data){
      this.curUserData = data;
      this.checkScoreFn(1)
    },
    isWrong(data){
      this.curUserData = data;
      this.checkScoreFn(0)
    },
    checkScoreFn(status){
      let params = {
        train_id:this.curUserData.train_id,
        train_user_id:this.curUserData.train_user_id,
        data_id:this.dataDetail.data.id,
        is_right:status
      };
      if(status == 1){
        params.score = 1;
      }
      this.api.course.checkTrainUserData(params).then((res)=>{
        this.$Message.success('操作成功');
        this.searchDataUser();
      })
    },
    closeDrawer(){
      this.dataStatStatus = false;
      if(this.curTab.id == 1){
        this.getDataDetail();
      }

    }
  },
  beforeDestroy(){
    clearTimeout(this.timer);
    clearTimeout(this.timer1);
    clearInterval(this.intervalTimer);
  }
}
</script>

<style scoped lang="scss">
.interact-detail{
  margin: 20px;
  .interact-detail-top{
    padding: 20px;
    background-color: #FFFFFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 74px;
    .interact-detail-top-tit{
      font-size: 18px;
      font-weight: bold;
      color: #333333;
    }
    .interact-detail-top-r{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >p{
        font-size: 24px;
        font-weight: bold;
        color: #333333;
      }
      >div{
        margin-left: 30px;
        width: 88px;
        height: 34px;
        background: #FC605C;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }
  .interact-detail-cont{
    margin-top: 10px;
    padding: 20px;
    background-color: #FFFFFF;
    .interact-detail-tab{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 16px;
      border-bottom: 1px solid #EEEEEE;
      font-weight: 400;
      color: #666666;

      >p{
        padding-bottom: 10px;
        margin-right: 30px;
        cursor: pointer;
      }
      .active{
        font-weight: bold;
        color: #4A79FF;
        border-bottom: 4px solid #5782FF;
      }
    }
  }
}
.answer-detail-data{
  margin: 20px;
  padding: 20px;
  background: #F6F7FA;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  .answer-detail-data-tit{


    font-size: 16px;
    font-weight: bold;
    color: #333333;
    line-height: 26px;
  }
  .answer-detail-data-option{
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #E8EAEC;
    display: flex;
    justify-content: flex-start;
    line-height: 24px;
  }
  .answer-detail-data-tag{
    margin-top: 10px;
    width: 80px;
    height: 25px;
    background: #D5FFE1;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: #00BB35;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.answer-detail-user{
  margin: 20px 20px 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  .answer-detail-user-item{
    margin-right: 20px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #333333;
    text-align: center;
    >img{
      margin-bottom: 4px;
      border-radius: 50%;
    }
  }
  .answer-detail-user-item:nth-child(7n){
    margin-right: 0;
  }
}
.answer-detail-user-tit{
  margin-left: 20px;
  font-size: 14px;
  font-weight: bold;
  color: #333333;
}
.answer-detail-user-fgx{
  margin: 0 20px 20px 20px;
  height: 1px;
  background: #E8EAEC;
}
.answer-detail-stat{
  margin: 0 20px 20px 20px;
  font-weight: bold;
  color: #5578F6;
  font-size: 14px;
  >span{
    padding:0 10px;
  }
}
.answer-detail-search{
  margin: 0 20px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  font-size: 14px;

  .answer-detail-search-item{
    margin-right: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    >div{
      margin-right: 6px;
    }

  }
}
.answer-detail-user-list{
  margin: 20px;
  .answer-detail-user-list-item{
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    >img{
      margin-right: 10px;
      border-radius: 50%;
    }
    .answer-detail-user-list-item-info{
      padding-bottom: 20px;
      flex: 1;
      width: 0;
      border-bottom: 1px solid #E8EAEC;
      .answer-detail-user-list-item-info-top{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        .name{
          font-weight: bold;
          color: #333333;
        }
        .tag{
          margin-left: 20px;
          width: 40px;
          height: 25px;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
        }
        .success{

          background: #D5FFE1;
          color: #00BB35;
        }
        .error{
          background: #FFE9E7;
          color: #FC605C;
        }
        .mark{
          background: #E6F3FF;
          color: #2595E8;
        }
      }
      .answer-detail-user-list-item-info-answer{
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 24px;
      }
      .answer-detail-user-list-item-info-file{
        margin-top: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .answer-detail-user-list-item-info-file-name{
          margin: 0 10px;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          word-wrap: break-word;
          word-break: break-all;
          flex: 1;
          width: 0;
        }

      }
      .answer-detail-user-list-item-info-btns{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        >div{
          margin-right: 10px;
          width: 80px;
          height: 28px;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          color: #FFFFFF;
          background: #5578F6;
          cursor: pointer;
        }
        .success{
          background: #07C160;
        }
        .error{
          background: #FC605C;
        }
      }
    }
  }
}
.answer-detail-tit-fgx{
  padding: 0 10px;
}
.no-data{
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  >p{
    margin-top: 35px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
  }
}
</style>

<template>
  <div>
    <div class="answer-stat">
      <div class="answer-stat-l">
        <div class="answer-stat-l-data" v-for="(item,index) in dataList" :key="item.id">
          <div class="answer-stat-l-data-tit">
            <p class="answer-stat-l-data-tit-icon"></p>
            <p>{{ item.name }} ({{item.list.length}}题)</p>
          </div>
          <div class="answer-stat-l-data-item" v-for="(sItem,sIndex) in item.list" :key="sItem.id">
            <p class="answer-stat-l-data-item-index">{{sIndex < 9 ? '0' + (sIndex + 1) :sIndex + 1}}.</p>
            <div class="answer-stat-l-data-item-nr">
              <div class="answer-stat-l-data-item-nr-top">
                <p class="answer-stat-l-data-item-nr-top-tit">
                  <Richtxt :dataObj="{val:sItem.name}" :isInline="true" :isDisabled="true"></Richtxt>
                </p>
                <div class="answer-stat-l-data-item-nr-top-nums" v-if="sItem.type == 1 || sItem.type == 2 || sItem.type == 3 || sItem.type == 4">
                  <div>
                    {{sItem.answer_user_count}}人参与此题
                    <span>|</span>
                    {{sItem.answer_right_user_count}}人答对
                    <span>|</span>
                    {{ sItem.answer_wrong_user_count}}人答错
                  </div>
                  <span class="answer-user-detail" @click="getSimpleDataDetail(sItem)">查看整体详情</span>
                </div>
                <div class="answer-stat-l-data-item-nr-top-nums" v-else>
                  {{sItem.answer_user_count}}人参与此题
                  <span class="answer-user-detail" @click="getSimpleDataDetail(sItem)">{{sItem.wait_check_count == 0 ? '详情' : '去批阅'}}</span>
                </div>
              </div>
              <template v-if="sItem.type == 1 || sItem.type == 2 || sItem.type == 3">
                <div class="answer-stat-l-data-item-nr-option" v-for="(option,i) in sItem.options" :key="option.id">
                  <div class="answer-stat-l-data-item-nr-option-l">
                    <div style="width: 15px;height: 15px">
                      <img src="../../../../assets/images/new_icon/cg.png" width="15" height="15" v-if="option.is_correct == 1" />
                    </div>
                    <div class="answer-stat-l-data-item-nr-option-nr">
                      <p>{{String.fromCharCode(i+65)}}.</p>
                      <div>
                        <Richtxt :dataObj="{val:option.name}" :isInline="true" :isDisabled="true"></Richtxt>
                      </div>
                    </div>
                  </div>
                  <div class="answer-stat-l-data-item-nr-option-r">
                    <p>{{option.choose_user_count}}人选择此题 <span class="answer-user-detail" @click="getOptionDetail(sItem,option,i)">详情</span></p>
                  </div>
                </div>
              </template>
              <template v-if="sItem.type == 4">
                <div class="answer-stat-l-data-item-nr-option" v-for="(option,i) in sItem.options" :key="option.id">
                  <div class="answer-stat-l-data-item-nr-option-l">
                    <div style="width: 15px;height: 15px">
                      <img src="../../../../assets/images/new_icon/cg.png" width="15" height="15" />
                    </div>
                    <div class="answer-stat-l-data-item-nr-option-nr">
                      <div>正确答案：{{option.name}}</div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="answer-stat-r">
        <p class="answer-stat-tit">提交名单<span>|</span>已提交 {{submitUserCount}} 人</p>
        <div class="answer-stat-r-status">
          <p :class="statusId == item.id ? 'active' : ''" v-for="item in statusList" :key="item.id" @click="changeStatus(item)">{{ item.name }}</p>
        </div>
        <div class="answer-stat-r-user">
          <div class="answer-stat-r-user-item" v-for="item in userList" :key="item.id">
            <div class="user-item-head">
              <img :src="item.avatar" width="68" height="68" />
              <img v-if="item.is_submit == '1'" src="../../../../assets/images/new_icon/cg.png" width="24" height="24" class="user-item-head-icon" />
            </div>
            <p class="user-item-name">{{ item.realname || item.nickname }}</p>
            <p class="user-item-id">ID{{item.id}}</p>
          </div>
          <div class="no-data" v-if="!userList.length && !userListLoading">
            <img src="../../../../assets/images/new_icon/nodata.png" width="158" height="82" />
            <p>暂无数据</p>
          </div>
        </div>
      </div>
    </div>


  </div>

</template>

<script>
import Richtxt from '@/components/production/richtxt.vue';
import util from '@/utils/tools.js';
import FileSaver from "file-saver";
export default {
  name: "answerStat",
  data(){
    return{
      statusId:'-1',

      interactTaskId:'',
      // dataList:[],
      // userList:[],
    }
  },
  props:{
    userList:{
      type:Array,
      default:()=>{
        return []
      }
    },
    dataList:{
      type:Array,
      default:()=>{
        return []
      }
    },
    userListLoading:{
      type:Boolean,
      default:true,
    },
    statusList:{
      type:Array,
      default:()=>{
        return []
      }
    },
    submitUserCount:{
      type:String,
      default:'0'
    }
  },
  components:{
    Richtxt
  },
  created(){
    this.interactTaskId = this.$route.query.id || '';

    // this.getCourseForm();
  },
  methods:{
    changeStatus(data){
      this.statusId = data.id;
      this.$emit('changeUserStatus',this.statusId)
    },
    getSimpleDataDetail(data){
      this.$emit('getSimpleDataDetail',data);
    },
    getOptionDetail(data,option,index){
      this.$emit('getOptionDetail', {
        data,option,index
      });
    }
  }
}
</script>

<style scoped lang="scss">
.answer-stat{
  display: flex;
  justify-content: space-between;

  .answer-stat-tit{
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    >span{
      padding: 0 10px;
    }
  }
  .answer-stat-l{
    margin-right: 36px;
    flex: 1;
    width: 0;
    .answer-stat-l-data{
      margin-top: 20px;
      .answer-stat-l-data-tit{
        margin-bottom: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        .answer-stat-l-data-tit-icon{
          margin-right: 6px;
          width: 4px;
          height: 16px;
          background: #4A79FF;
        }
      }
      .answer-stat-l-data-item{

        display: flex;
        justify-content: flex-start;
        .answer-stat-l-data-item-index{
          margin-top: 10px;
          width: 40px;
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
        }
        .answer-stat-l-data-item-nr{
          flex: 1;
          width: 0;
          .answer-stat-l-data-item-nr-top{
            padding: 15px 20px;
            background: #F8F8F9;
            border: 1px solid #E8EAEC;
            border-radius: 1px;
            .answer-stat-l-data-item-nr-top-tit{
              font-size: 16px;
              font-weight: bold;
              color: #333333;
            }
            .answer-stat-l-data-item-nr-top-nums{
              margin-top: 10px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              font-size: 14px;
              font-weight: 400;
              justify-content: space-between;
              color: #999999;
              >span{
                font-size: 16px;
              }
            }
          }
          .answer-user-detail{
            color:#4A79FF;
            cursor: pointer;
          }
          .answer-stat-l-data-item-nr-option{
            padding: 20px 0;
            display: flex;
            justify-content: flex-start;
            border-bottom: 1px solid #E8EAEC;
            font-size: 14px;
            color: #333333;
            .answer-stat-l-data-item-nr-option-l{
              margin-right: 100px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              flex: 1;
              width: 0;
              >div{
                margin-top: 3px;
                margin-right: 4px;
              }
              .answer-stat-l-data-item-nr-option-nr{
                display: flex;
                justify-content: flex-start;
                flex: 1;
                width: 0;
                >p{
                  width:  20px;
                  >span{
                    color:#4A79FF;
                    cursor: pointer;
                  }
                }
                >div{
                  width: 0;
                  flex:1;
                }
              }
            }
          }
        }
      }
    }

  }
  .answer-stat-r{
    padding: 30px 20px;
    width: 462px;
    background: #F8F8F9;
    border-radius: 1px;
    .answer-stat-r-status{
      margin: 18px 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      >p{
        margin-right: 20px;
        cursor: pointer;
      }
      .active{
        font-weight: bold;
        color: #4A79FF;
      }
    }
    .answer-stat-r-user{
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      .answer-stat-r-user-item{
        margin: 0 10px 10px 0;
        width: 98px;
        height: 140px;
        background: #FFFFFF;
        border-radius: 4px;
        text-align: center;
        font-size: 14px;
        color: #333333;
        .user-item-head{
          margin: 0 auto;
          padding-top: 10px;
          width: 68px;
          position: relative;
          >img{
            border-radius: 50%;
          }
          .user-item-head-icon{
            position: absolute;
            bottom: 0;
            right: 0;
          }
        }
        .user-item-name{
          margin-top: 4px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .answer-stat-r-user-item:nth-child(4n){
        margin-right: 0;
      }
    }
  }
}
.answer-detail-tit-fgx{
  padding: 0 10px;
}
.blue{
  color:#5578F6;
  padding: 0 4px;
}
.no-data{
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  >p{
    margin-top: 35px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
  }
}
</style>

<template>
  <div class="interact-rank">
    <div v-if="taskOver">
      <el-table
          :data="tableData"
          border
          class="table"
      >
        <el-table-column
            prop="id"
            label="Id">
        </el-table-column>
        <el-table-column
            prop="title"
            label="题目"
        >
        </el-table-column>
        <el-table-column
            prop="data_count"
            label="题型">
          <template slot-scope="scope">
            {{dataTypes[scope.row.type] }}
          </template>
        </el-table-column>
        <el-table-column
            prop="right_count"
            label="答题人数">
          <template slot-scope="scope">
            {{scope.row.trainData && scope.row.trainData.totalCount }}
          </template>
        </el-table-column>
        <el-table-column
            prop="data_count"
            label="答对人数">
          <template slot-scope="scope">
            {{scope.row.trainData && scope.row.trainData.rightCount}}
          </template>
        </el-table-column>
        <el-table-column
            prop="data_count"
            label="正确率">
          <template slot-scope="scope">
            {{scope.row.trainData && scope.row.trainData.rightRate + '%'}}
          </template>
        </el-table-column>
        <el-table-column
            prop="data_count"
            label="操作">
          <template slot-scope="scope">
            <el-button type="primary" size="small"  @click="getSimpleDataDetail(scope.row)">查看详情</el-button>
          </template>

        </el-table-column>
      </el-table>
      <div class="page">
        <MyPage :total="total" :current="page" @changePage="changePage" @changePageSize="changePageSize"></MyPage>
      </div>
    </div>

    <div class="no-data" v-if="!taskOver">
      <img src="../../../../assets/images/new_icon/nodata.png" width="158" height="82" />
      <p>请结束答题后查看</p>
    </div>
  </div>
</template>

<script>
import util from '@/utils/tools.js';
export default {
  name: "rank",
  data(){
    return{
      util:util,
      tableData:[],
      total:0,
      page:1,
      pageSize:10,
      interactTaskId:'',
      dataTypes:{}
    }
  },
  props:{
    taskOver:{
      type:Boolean,
      default:false,
    },
    trainId:{
      type:String,
      default:'',
    },
    dataStatStatus:{
      type:Boolean,
      default:false,
    }
  },
  watch:{
    dataStatStatus(newV){
      if(!newV){
        this.getList();
      }
    }
  },
  created(){
    this.interactTaskId = this.$route.query.id || '';
    this.getList();
  },
  methods:{
    getList(){
      let params={
        train_id:this.trainId,
        page:this.page,
        page_size:this.pageSize,
      };
      this.api.dataset.trainDataList(params).then((res)=>{
        this.tableData = res.list;
        this.total = Number(res.count);
        this.dataTypes = res.data_types;
      })
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.page = 1;
      this.pageSize = pageSize;
      this.getList();
    },
    getSimpleDataDetail(data){
      this.$emit('getSimpleDataDetail',data);
    },
  }
}
</script>

<style scoped lang="scss">
.interact-rank{
  margin-top: 20px;
  .page{
    margin-top: 20px;
    text-align: right;
  }
  .no-data{
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    >p{
      margin-top: 35px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
    }
  }
}
</style>

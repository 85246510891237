<template>
<div class="interact-rank">
  <div v-if="taskOver">
    <el-form ref="form" :model="searchForm" label-width="40px">
      <el-form-item label="状态">
        <el-radio-group v-model="searchForm.is_check" v-for="item in ScoringStatus" :key="item.id" @change="getList()">
          <el-radio :label="item.id" style="margin-right: 20px;">{{ item.name }}</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <el-table
        :data="tableData"
        border
        class="table"
    >
      <el-table-column
          prop="rank"
          label="排名">
      </el-table-column>
      <el-table-column
          prop="name"
          label="学生名称"
      >
        <template slot-scope="scope">
          {{scope.row.user && (scope.row.user.nickname || scope.row.user.realname) }}
        </template>
      </el-table-column>
      <el-table-column
          prop="data_count"
          label="ID">
        <template slot-scope="scope">
          {{scope.row.user_id }}
        </template>
      </el-table-column>
      <el-table-column
          prop=""
          label="状态">
        <template slot-scope="scope">
          {{statuses[scope.row.status] }}
        </template>
      </el-table-column>
      <el-table-column
          prop="right_count"
          label="答对数量">
      </el-table-column>
      <el-table-column
        prop="data_count"
        label="答错数量">
      <template slot-scope="scope">
        {{Number(scope.row.have_finished) - Number(scope.row.right_count) - Number(scope.row.wait_check_count)}}
      </template>
    </el-table-column>
      <el-table-column
          prop="data_count"
          label="待判卷数量">
        <template slot-scope="scope">
          {{scope.row.wait_check_count}}
        </template>
      </el-table-column>
      <el-table-column
          prop="data_count"
          label="用时">
        <template slot-scope="scope">
          {{scope.row.surplusTime.hours ? (scope.row.surplusTime.hours < 10 ? '0' + scope.row.surplusTime.hours : scope.row.surplusTime.hours) : '00'}}:{{scope.row.surplusTime.minutes ? (scope.row.surplusTime.minutes < 10 ? '0' + scope.row.surplusTime.minutes : scope.row.surplusTime.minutes)  : '00'}}:{{scope.row.surplusTime.seconds ? (scope.row.surplusTime.seconds < 10 ? '0' + scope.row.surplusTime.seconds : scope.row.surplusTime.seconds) : '00'}}
        </template>
      </el-table-column>
      <el-table-column
          prop="data_count"
          label="操作">
        <template slot-scope="scope">
          <el-button type="primary" size="small" v-if="scope.row.status == 4 || scope.row.status == 5" @click="goMark(scope.row)">阅卷</el-button>
          <el-button type="primary" size="small" v-else @click="goDetail(scope.row)">答题详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="page">
      <MyPage :total="total" :current="page" @changePage="changePage" @changePageSize="changePageSize"></MyPage>
    </div>
  </div>
  <div class="no-data" v-if="!taskOver">
    <img src="../../../../assets/images/new_icon/nodata.png" width="158" height="82" />
    <p>请结束答题后查看</p>
  </div>
</div>
</template>

<script>
import util from '@/utils/tools.js';
export default {
  name: "rank",
  data(){
    return{
      util:util,
      tableData:[],
      total:0,
      page:1,
      pageSize:10,
      interactTaskId:'',
      statuses:{},
      searchForm:{
        is_check:"-1"
      },
      ScoringStatus:[
        {
          id:"-1",
          name:"全部"
        },
        {
          id:"0",
          name:"未阅卷"
        },
        {
          id:"1",
          name:"已阅卷"
        }
      ]
    }
  },
  props:{
    taskOver:{
      type:Boolean,
      default:false,
    }
  },
  created(){
    this.interactTaskId = this.$route.query.id || '';
    this.getList();
  },
  methods:{
    getList(){
      const { searchForm }=this;
      let params = {
        interact_task_id:this.interactTaskId,
        page:this.page,
        pageSize:this.pageSize,
        ...searchForm
      }
      this.api.course.courseInteractTaskRankList(params).then((res)=>{
        this.tableData = res.list;
        this.total = Number(res.count);
        this.statuses = res.statuses;
        this.tableData.forEach((item)=>{
          let userTime = util.timeFormate(item.use_time);
          this.$set(item,'surplusTime',userTime);
        })
      })
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.page = 1;
      this.pageSize = pageSize;
      this.getList();
    },
    goDetail(data){
      this.$router.push({
        path:'/teacher/course/interactExam',
        query:{
          id:data.train_id,
          isResult:true,
          userId:data.user_id
        }
      })
    },
    goMark(data){
      this.$router.push({
        path:'/teacher/course/interactExam',
        query:{
          id:data.train_id,
          isResult:true,
          isMark:true,
          userId:data.user_id
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.interact-rank{
  margin-top: 20px;
  .page{
    margin-top: 20px;
    text-align: right;
  }
  .no-data{
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    >p{
      margin-top: 35px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
    }
  }
}
</style>

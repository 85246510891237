import { render, staticRenderFns } from "./dataStat.vue?vue&type=template&id=691c6995&scoped=true&"
import script from "./dataStat.vue?vue&type=script&lang=js&"
export * from "./dataStat.vue?vue&type=script&lang=js&"
import style0 from "./dataStat.vue?vue&type=style&index=0&id=691c6995&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "691c6995",
  null
  
)

export default component.exports